import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import furqan from "../../images/about-us/furqan_aziz_profile_picture.svg"
import our from "../../images/about-us/our_inspiring_vision.webp"
import Mission from "../../images/about-us/Our_Impactful_Mission.webp"
import twitter from "../../images/react-icons/twitter-blue-logo.svg"
import mail from "../../images/svg/mail.svg"
import linkedin from "../../images/svg/linkedin.png"
import * as styles from "./CeoMessage.module.scss"

const CeoMessage = ({ strapiData }) => (
  <div className={styles.CeoMessage}>
    <Container>
      <div>
        <div className={styles.message}>
          <Row>
            <Col
              xl={{ span: 8, order: 1 }}
              lg={{ span: 12, order: 2 }}
              md={{ span: 12, order: 2 }}
              sm={{ span: 12, order: 2 }}
              xs={{ span: 12, order: 2 }}
            >
              <div className={styles.text}>
                <h2
                  dangerouslySetInnerHTML={{ __html: strapiData[0]?.title }}
                />
                <p
                  dangerouslySetInnerHTML={{
                    __html: strapiData[0]?.description?.description,
                  }}
                />
              </div>
            </Col>
            <Col
              xl={{ span: 4, order: 2 }}
              lg={{ span: 12, order: 1 }}
              md={{ span: 12, order: 1 }}
              sm={{ span: 12, order: 1 }}
              xs={{ span: 12, order: 1 }}
            >
              <div className={styles.cta}>
                <img
                  src={furqan}
                  decoding="async"
                  loading="lazy"
                  alt="furqan-aziz-profile"
                />
                <p className={styles.Furqan}>Furqan Aziz</p>
                <p>CEO of InvoZone , InvoBlox, InvoGames</p>
                <div className={styles.socialBtn}>
                  <a
                    className="btn btn-primary"
                    href={"https://www.linkedin.com/in/furqan-aziz/"}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className={styles.socialIcons}
                      src={linkedin}
                      alt="twitter"
                      loading="lazy"
                      decoding="async"
                    />
                  </a>
                  <a
                    className="btn btn-primary "
                    href={`mailto:furqan@invozone.com`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className={styles.socialIcons}
                      src={mail}
                      alt="twitter"
                      loading="lazy"
                      decoding="async"
                    />
                  </a>
                  <a
                    className="btn btn-primary "
                    href={`https://twitter.com/ItsFurqanAziz`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className={styles.socialIcons}
                      src={twitter}
                      alt="twitter"
                      loading="lazy"
                      decoding="async"
                    />
                  </a>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <div className={styles.inspiring}>
          <Row>
            <Col xl={6}>
              <p
                className={styles.inspirHeading2}
                dangerouslySetInnerHTML={{ __html: strapiData[1]?.title }}
              />
              <img
                className={styles.missionImg}
                src={our}
                decoding="async"
                loading="lazy"
                alt="our_inspiring_vision"
              />
            </Col>
            <Col xl={6}>
              <div className={styles.inspirinText}>
                <h2
                  className={styles.inspirHeading}
                  dangerouslySetInnerHTML={{ __html: strapiData[1]?.title }}
                />
                <p
                  dangerouslySetInnerHTML={{
                    __html: strapiData[1]?.description?.description,
                  }}
                />
              </div>
            </Col>
          </Row>
          <div className={styles.inspirinText2}>
            <Row>
              <Col
                xl={{ span: 6, order: 1 }}
                lg={{ span: 12, order: 2 }}
                md={{ span: 12, order: 2 }}
                sm={{ span: 12, order: 2 }}
                xs={{ span: 12, order: 2 }}
              >
                <div className={styles.inspirinText}>
                  <h2
                    className={styles.inspirHeading}
                    dangerouslySetInnerHTML={{ __html: strapiData[2]?.title }}
                  />
                  <p
                    dangerouslySetInnerHTML={{
                      __html: strapiData[2]?.description?.description,
                    }}
                  />
                </div>
              </Col>
              <Col
                xl={{ span: 6, order: 2 }}
                lg={{ span: 12, order: 1 }}
                md={{ span: 12, order: 1 }}
                sm={{ span: 12, order: 1 }}
                xs={{ span: 12, order: 1 }}
              >
                <p
                  className={styles.inspirHeading2}
                  dangerouslySetInnerHTML={{ __html: strapiData[2]?.title }}
                />
                <img
                  className={styles.missionImg}
                  src={Mission}
                  decoding="async"
                  loading="lazy"
                  alt="Our_Impactful_Mission"
                />
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </Container>
  </div>
)

export default CeoMessage
