import React, { useEffect, useRef } from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import LeftArrow from "../../images/softwere-development/left.svg"
import RightArrow from "../../images/softwere-development/right.svg"
import Slider from "react-slick"
import "slick-carousel/slick/slick-theme.scss"
import "slick-carousel/slick/slick.scss"
import * as styles from "./Sneak.module.scss"
import "./Sneak.scss"

const Team = ({ strapiData }) => {
  const sliderRef = useRef(null)
  const viewRef = useRef(null)

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          // Run the count animation when the element is viewable
          sliderRef.current.slickPlay()
        } else {
          sliderRef.current.slickPause()
        }
      },
      { threshold: 0.5 } // Adjust the threshold as per your requirement
    )

    if (viewRef.current) {
      observer.observe(viewRef.current)
    }

    // Cleanup the observer when component unmounts
    return () => {
      if (viewRef.current) {
        observer.unobserve(viewRef.current)
      }
    }
  }, [])

  function Arrow(props) {
    const { onClick } = props
    return (
      <div className={styles.iconContainer}>
        <div className={styles.portfolioArrowRightIconCover} onClick={onClick}>
          <img
            decoding="async"
            loading="lazy"
            alt="arrow"
            src={RightArrow}
            onClick={onClick}
            className={styles.portfolioArrowIcon}
          />
        </div>
      </div>
    )
  }
  function PrevArrow(props) {
    const { onClick } = props
    return (
      <div className={styles.iconContainerLeft}>
        <div className={styles.portfolioArrowIconCover} onClick={onClick}>
          <img
            decoding="async"
            loading="lazy"
            alt="arrow"
            src={LeftArrow}
            className={styles.portfolioArrowIcon}
          />
        </div>
      </div>
    )
  }

  const settings = {
    autoplay: true,
    dots: false,
    dotsClass: "android-dots",
    slidesToShow: 2,
    slidesToScroll: 1,
    infinite: true,
    speed: 200,
    arrows: true,
    prevArrow: <PrevArrow />,
    nextArrow: <Arrow />,
    lazyLoad: "ondemand",

    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 1,
          arrows: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          arrows: true,
          slidesToShow: 1,
        },
      },
    ],
  }

  return (
    <div className={styles.sneak} ref={viewRef}>
      <Container>
        <Row>
          <Col lg={5}>
            <div className={styles.sneakText}>
              <h2 dangerouslySetInnerHTML={{ __html: strapiData?.title }} />
              <p dangerouslySetInnerHTML={{ __html: strapiData?.subTitle }} />
            </div>
          </Col>
          <Col lg={7}>
            <Slider
              {...settings}
              className={styles.SliderWidth}
              ref={sliderRef}
            >
              {strapiData?.cards &&
                strapiData?.cards?.map((e, i) => (
                  <Col xs={12} key={i}>
                    <div>
                      <img
                        src={e?.image1 && e?.image1[0]?.localFile?.publicURL}
                        alt={"Culture"}
                        decoding="async"
                        loading="lazy"
                        className={styles.sliderImg}
                      />
                    </div>
                  </Col>
                ))}
            </Slider>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Team
