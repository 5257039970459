// extracted by mini-css-extract-plugin
export var CeoMessage = "CeoMessage-module--CeoMessage--4d1e2";
export var Furqan = "CeoMessage-module--Furqan--6d9af";
export var cta = "CeoMessage-module--cta--62b0f";
export var inspirHeading = "CeoMessage-module--inspirHeading--3ac98";
export var inspirHeading2 = "CeoMessage-module--inspirHeading2--9d27e";
export var inspirinText = "CeoMessage-module--inspirinText--1cc65";
export var inspirinText2 = "CeoMessage-module--inspirinText2--73140";
export var inspiring = "CeoMessage-module--inspiring--ba305";
export var message = "CeoMessage-module--message--6449a";
export var missionImg = "CeoMessage-module--missionImg--c61a5";
export var socialBtn = "CeoMessage-module--socialBtn--a6356";
export var socialIcons = "CeoMessage-module--socialIcons--b75b2";
export var text = "CeoMessage-module--text--65aef";