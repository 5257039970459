import React from "react"
import Card from "react-bootstrap/Card"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import linkedin from "../../images/about-us/linkedin.svg"
import gmail from "../../images/about-us/gmail.svg"
import whatsapp from "../../images/about-us/whatsapp.svg"
import * as styles from "./Team.module.scss"

const Team = ({ strapiData }) => {
  return (
    <section className={styles.teamBg}>
      <Container>
        <div className={styles.text}>
          <h2 dangerouslySetInnerHTML={{ __html: strapiData?.title }} />
          <p dangerouslySetInnerHTML={{ __html: strapiData?.subTitle }} />
        </div>
        <Row className="justify-content-center">
          {strapiData?.cards &&
            strapiData?.cards?.map(item => (
              <Card className={styles.card}>
                <div className={styles.circle2}></div>
                <div className={styles.circle}>
                  <img
                    decoding="async"
                    loading="lazy"
                    src={item?.image1[0]?.localFile?.publicURL}
                    className={styles.cardImage}
                    alt="members"
                  />
                </div>
                <div className={styles.socialBtn}>
                  <a
                    className={styles.btnPrimary}
                    href={item?.buttons[1]?.title}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={linkedin}
                      decoding="async"
                      loading="lazy"
                      alt="icon"
                    />
                  </a>
                  <a
                    className={styles.btnPrimary}
                    href={`mailto:${item?.buttons[2]?.title}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={gmail}
                      decoding="async"
                      loading="lazy"
                      alt="icon"
                    />
                  </a>
                  <a
                    className={styles.btnPrimary}
                    href={item?.buttons[0]?.title}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={whatsapp}
                      decoding="async"
                      loading="lazy"
                      alt="icon"
                    />
                  </a>
                </div>
                <Card.Body className="text-center">
                  <p className="font-weight-bold mb-1">{item?.title}</p>
                  <p className={styles.post}>{item?.subTitle}</p>
                </Card.Body>
              </Card>
            ))}
        </Row>
      </Container>
    </section>
  )
}

export default Team
