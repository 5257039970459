// extracted by mini-css-extract-plugin
export var bannerBtn = "Milestones2-module--bannerBtn--c9e65";
export var btn_white1_border = "Milestones2-module--btn_white1_border--3c8d7";
export var card = "Milestones2-module--card--527cd";
export var cardWrapper = "Milestones2-module--cardWrapper--48db5";
export var description = "Milestones2-module--description--07b71";
export var dots5 = "Milestones2-module--dots5--01ac9";
export var heading = "Milestones2-module--heading--faa2c";
export var hex = "Milestones2-module--hex--98dd2";
export var peakNumber = "Milestones2-module--peakNumber--a47e4";
export var react = "Milestones2-module--react--dc2a0";
export var serial2 = "Milestones2-module--serial2--c81d2";
export var subservice = "Milestones2-module--subservice--0b8c0";
export var teamBtn = "Milestones2-module--teamBtn--286f9";