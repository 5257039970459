import { graphql } from "gatsby"
import React from "react"
import Banner from "../components/about-sections/Banner"
import CeoMessage from "../components/about-sections/CeoMessage"
import Curious from "../components/about-sections/Curious"
import Engineering from "../components/about-sections/Engineering"
import Find from "../components/about-sections/Find"
import Milestones from "../components/about-sections/Milestones"
import Milestones2 from "../components/about-sections/Milestones2"
import Sneak from "../components/about-sections/Sneak"
import Team from "../components/about-sections/Team"
import ContactSales from "../components/common/ContactSales"
import EngagementModels from "../components/common/Engagement-Model/EngagementModels"
import SEORevamp from "../components/common/SEO_Revamp"
import MainLayout from "../layouts/MainLayout"
export const Head = ({ data }) => {
  const { metaTitle, metaDescription } = data?.strapiPage?.seo
  return (
    <SEORevamp
      title={metaTitle}
      description={metaDescription}
      image="https://invozone-backend.s3.amazonaws.com/About_US_f993978d8e.png"
    />
  )
}
const AboutUsPage = ({ data }) => {
  // console.log(data)
  const [isMobile, setIsMobile] = React.useState(false)
  const [isTablet, setIsTablet] = React.useState(false)

  const handleResize = () => {
    if (window.innerWidth <= 767) {
      setIsMobile(true)
      setIsTablet(false)
    } else if (window.innerWidth <= 1281) {
      setIsTablet(true)
      setIsMobile(false)
    } else {
      setIsMobile(false)
      setIsTablet(false)
    }
  }
  React.useEffect(() => {
    setIsMobile(window.innerWidth <= 767)
    setIsTablet(window.innerWidth <= 1281)
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  const heroSection = data?.strapiPage?.sections[0]
  const curious = data?.strapiPage?.sections[1]
  const ceoMessage = [
    data?.strapiPage?.sections[2],
    data?.strapiPage?.sections[3],
    data?.strapiPage?.sections[4],
  ]
  const sneak = data?.strapiPage?.sections[5]
  const engineering = data?.strapiPage?.sections[6]
  const find = data?.strapiPage?.sections[7]
  const team = data?.strapiPage?.sections[8]
  const fuelSuccess = data?.strapiPage?.sections[9]
  const milestones = data?.strapiPage?.sections[10]

  return (
    <MainLayout bgChanged={false}>
      <Banner strapiData={heroSection} />
      <Curious strapiData={curious} />
      <CeoMessage strapiData={ceoMessage} />
      <Sneak strapiData={sneak} />
      <Engineering strapiData={engineering} />
      <Find strapiData={find} />
      <Team strapiData={team} />
      <EngagementModels strapiData={fuelSuccess} />
      {!isMobile ? <Milestones strapiData={milestones} /> : <Milestones2 />}
      <ContactSales />
    </MainLayout>
  )
}

export const query = graphql`
  query mobileAppDevelopments {
    strapiPage(slug: { eq: "about-us" }) {
      sections {
        title
        subTitle
        subTitle2
        cards {
          title
          subTitle
          description: childStrapiComponentCardsCardDescriptionTextnode {
            description
          }
          image1 {
            alternativeText
            localFile {
              publicURL
            }
          }
          image2 {
            alternativeText
            localFile {
              publicURL
            }
          }
          buttons {
            title
            url
          }
        }
        secImages {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
            publicURL
          }
          url
        }
        buttons {
          title
          url
        }
        description: childStrapiComponentSectionsSectionDescriptionTextnode {
          id
          description
        }
      }
      seo {
        metaTitle
        metaDescription
      }
    }
  }
`

export default AboutUsPage
