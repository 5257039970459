import { Link } from "gatsby"
import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import * as styles from "./Banner.module.scss"

const Banner = ({ strapiData }) => (
  <div className={styles.careers}>
    <Container>
      <Row className="justify-content-center">
        <Col xl={6} lg={12}>
          <div className={styles.bannrtText}>
            <h1
              className="main-banner-heading-55"
              dangerouslySetInnerHTML={{ __html: strapiData?.title }}
            />
            <div className="font-weight-normal pt-2">
              <p
                dangerouslySetInnerHTML={{
                  __html: strapiData?.description?.description,
                }}
              />
            </div>
            <div className={`${styles.bannerBtn}`}>
              <Link to={strapiData?.buttons[0]?.url} className={styles.btn}>
                {strapiData?.buttons[0]?.title}
              </Link>
            </div>
          </div>
        </Col>
        <Col xl={6} lg={12} className="mt-5 mt-md-0 pl-md-5 text-center"></Col>
      </Row>
    </Container>
  </div>
)

export default Banner
