// extracted by mini-css-extract-plugin
export var Frame = "Engineering-module--Frame--e3299";
export var Vector = "Engineering-module--Vector--e5159";
export var arrow4 = "Engineering-module--arrow4--78ad4";
export var card = "Engineering-module--card--58237";
export var cards = "Engineering-module--cards--56c90";
export var cardsData = "Engineering-module--cardsData--eccc5";
export var cir = "Engineering-module--cir--f4bc5";
export var doots = "Engineering-module--doots--b47af";
export var dots = "Engineering-module--dots--3ae48";
export var dots1 = "Engineering-module--dots1--cc00b";
export var imgBg = "Engineering-module--imgBg--a1d6e";
export var number = "Engineering-module--number--ff5c0";
export var years = "Engineering-module--years--d609b";