import React from "react"
import Container from "react-bootstrap/Container"
import { Link } from "gatsby"
import Dot from "../../images/IOS/closeDot1.png"
import rec from "../../images/IOS/rec.png"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import circle from "../../images/IOS/circle.png"
import cir from "../../images/IOS/cir.png"
import story2 from "../../images/about-us/company-highlights-tablet.svg"
import Vector02 from "../../images/about-us/Vector02.svg"
import dotsR from "../../images/about-us/dots.png"
import box from "../../images/about-us/box.png"
import arrow4 from "../../images/about-us/arrow4.png"
import * as styles from "./Milestones.module.scss"

const Milestones = ({ strapiData }) => {
  const image = getImage(strapiData?.secImages[0]?.localFile)

  return (
    <div className={styles.Ideation}>
      <img
        src={Dot}
        className={styles.dots}
        decoding="async"
        loading="lazy"
        alt="rectangle"
      />
      <img
        src={box}
        className={styles.box}
        decoding="async"
        loading="lazy"
        alt="rectangle"
      />
      <img
        src={arrow4}
        className={styles.arrow4}
        decoding="async"
        loading="lazy"
        alt="rectangle"
      />
      <img
        src={rec}
        className={styles.rec}
        decoding="async"
        loading="lazy"
        alt="rectangle"
      />
      <img
        src={circle}
        className={styles.circle}
        decoding="async"
        loading="lazy"
        alt="rectangle"
      />
      <img
        src={Vector02}
        className={styles.Vector02}
        decoding="async"
        loading="lazy"
        alt="rectangle"
      />
      <img
        src={Vector02}
        className={styles.Vector03}
        decoding="async"
        loading="lazy"
        alt="rectangle"
      />
      <img
        src={Dot}
        className={styles.dots3}
        decoding="async"
        loading="lazy"
        alt="rectangle"
      />
      <img
        src={Dot}
        className={styles.dots4}
        decoding="async"
        loading="lazy"
        alt="rectangle"
      />
      <img
        src={dotsR}
        className={styles.dotsR}
        decoding="async"
        loading="lazy"
        alt="rectangle"
      />
      <Container>
        <h2
          className={styles.heading}
          dangerouslySetInnerHTML={{ __html: strapiData?.title }}
        />
        <p
          className={styles.dec}
          dangerouslySetInnerHTML={{
            __html: strapiData?.subTitle,
          }}
        />
        <div className={styles.cardsData}>
          <div className={styles.leftText}>
            {strapiData?.cards &&
              strapiData?.cards?.map(
                (e, i) =>
                  i < 5 && (
                    <div className={styles.cards} key={i}>
                      <div className={styles.peakNumber2}>
                        <span>{e?.title}</span>
                      </div>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: e?.subTitle,
                        }}
                      />
                    </div>
                  )
              )}
          </div>
          <div className={styles.closeUp}>
            {image ? (
              <GatsbyImage
                placeholder="blurred"
                decoding="async"
                loading="lazy"
                image={image}
                alt={strapiData?.title}
              />
            ) : (
              <img
                src={strapiData?.secImages[0]?.localFile?.publicURL}
                alt={strapiData?.title}
                // src={story}
                decoding="async"
                loading="lazy"
              />
            )}
          </div>
          <div className={styles.closeUp2}>
            {image ? (
              <GatsbyImage
                placeholder="blurred"
                decoding="async"
                loading="lazy"
                image={image}
                alt={strapiData?.title}
              />
            ) : (
              <img src={story2} decoding="async" loading="lazy" alt="icon" />
            )}
          </div>
          <div className={styles.rightText}>
            {strapiData?.cards &&
              strapiData?.cards?.map(
                (e, i) =>
                  i > 4 && (
                    <div className={styles.cards2} key={i}>
                      <div className={styles.peakNumber}>
                        <span>{e?.title}</span>
                      </div>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: e?.subTitle,
                        }}
                      />
                    </div>
                  )
              )}
          </div>
        </div>
        <div className={`${styles.bannerBtn}`}>
          {strapiData?.buttons[0] && (
            <Link
              to={strapiData?.buttons[0]?.url}
              className={styles.btn_white1_border}
            >
              {strapiData?.buttons[0]?.title}
            </Link>
          )}
        </div>
        <img
          src={Dot}
          className={styles.dots2}
          decoding="async"
          loading="lazy"
          alt="rectangle"
        />
        <img
          src={cir}
          className={styles.cir}
          decoding="async"
          loading="lazy"
          alt="rectangle"
        />
        <img
          src={Dot}
          className={styles.dots5}
          decoding="async"
          loading="lazy"
          alt="rectangle"
        />
      </Container>
    </div>
  )
}

export default Milestones
