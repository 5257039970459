import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import dots from "../../images/softwere-development/Group1.png"
import reactangel from "../../images/softwere-development/Vector.svg"
import img1 from "../../images/about-us/2013.svg"
import img2 from "../../images/about-us/2014.svg"
import img3 from "../../images/about-us/2015.svg"
import img4 from "../../images/about-us/2016.svg"
import img5 from "../../images/about-us/2017.svg"
import img6 from "../../images/about-us/2018.svg"
import img7 from "../../images/about-us/2019.svg"
import img8 from "../../images/about-us/2020.svg"
import img9 from "../../images/about-us/2021.svg"
import img10 from "../../images/about-us/2022.svg"
import img11 from "../../images/about-us/2023.svg"
import * as styles from "./Milestones2.module.scss"

const cardData = [
  {
    image: img1,
    title: `2013`,
    dec: `Startup ideation and planning. Analyzed the market and identified areas to improve.`,
  },
  {
    image: img2,
    title: `2014`,
    dec: `Official launch of InvoZone. Set up our first remote office, and boarded our very first Fintech client.`,
  },
  {
    image: img3,
    title: `2015`,
    dec: `Industry recognition and awards for software development excellence, reinforcing our leadership reputation.`,
  },
  {
    image: img4,
    title: `2016`,
    dec: `Achieved our first milestone of 100 projects with many more in the pipelines.`,
  },
  {
    image: img5,
    title: `2017`,
    dec: `Strategic partnerships with universities and institutes, nurturing talent for future growth.`,
  },
  {
    image: img6,
    title: `2018`,
    dec: `Reached the 200 project mark; expanded operations in Asia with the opening of our office in Malaysia`,
  },
  {
    image: img7,
    title: `2019`,
    dec: `Opened our office in Pakistan with a vision to become the go-to technology partner for business globally.`,
  },
  {
    image: img8,
    title: `2020`,
    dec: `Announced our continue global expansion with the launch of our product development office in Toronto.`,
  },
  {
    image: img9,
    title: `2021`,
    dec: `Completed over 300+ projects (and counting), clocked in 350k+ hours with a team of 500+ employees.`,
  },
  {
    image: img10,
    title: `2022`,
    dec: `Established global offices in Canada and Malaysia, scouting global tech talent with a dedicated recruitment team.`,
  },
  {
    image: img11,
    title: `2023`,
    dec: `Listed in top 100 Web App Development Companies by Clutch.co.`,
  },
]

const Milestones2 = ({ strapiData }) => {
  return (
    <div className={`${styles.subservice}`}>
      <div className={styles.react}>
        <img
          src={reactangel}
          decoding="async"
          loading="lazy"
          alt="reactangel"
        />
      </div>

      <Container>
        <h2
          className={styles.heading}
          dangerouslySetInnerHTML={{ __html: strapiData?.title }}
        />
        <h2 className={styles.heading}>
          InvoZone: Shaping the Future with Milestones of Innovation!
        </h2>
        <p className={styles.description}>
          Through years of pioneering progress, we continue to forge ahead,
          crafting an inspiring and transformative journey.
        </p>
        <div
          className={styles.description}
          dangerouslySetInnerHTML={{
            __html: strapiData?.subTitle,
          }}
        />
        <div className={styles.dots5}>
          <img src={dots} decoding="async" loading="lazy" alt="dots" />
        </div>
        <Row className={`${styles.cardWrapper}`}>
          {cardData?.map((e, i) => (
            <Col xl={4} md={6} xs={12} key={i}>
              <div className={styles.card}>
                <img
                  src={e?.image}
                  decoding="async"
                  loading="lazy"
                  alt="icon"
                />
                <h3>{e?.title}</h3>
                <p
                  dangerouslySetInnerHTML={{
                    __html: e?.description?.description,
                  }}
                />
                <p>{e?.dec}</p>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  )
}

export default Milestones2
