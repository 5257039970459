// extracted by mini-css-extract-plugin
export var Frame = "Milestones-module--Frame--12ddc";
export var Ideation = "Milestones-module--Ideation--8546b";
export var Vector02 = "Milestones-module--Vector02--cdd89";
export var Vector03 = "Milestones-module--Vector03--e57d7";
export var arrow4 = "Milestones-module--arrow4--cbc68";
export var bannerBtn = "Milestones-module--bannerBtn--585d3";
export var box = "Milestones-module--box--07b78";
export var btn_white1_border = "Milestones-module--btn_white1_border--4b8af";
export var cards = "Milestones-module--cards--b045e";
export var cards2 = "Milestones-module--cards2--e2392";
export var cardsData = "Milestones-module--cardsData--1e4fe";
export var cir = "Milestones-module--cir--fd302";
export var circle = "Milestones-module--circle--ff5df";
export var closeUp = "Milestones-module--closeUp--1088d";
export var closeUp2 = "Milestones-module--closeUp2--5214f";
export var dec = "Milestones-module--dec--19186";
export var dots = "Milestones-module--dots--76db2";
export var dots2 = "Milestones-module--dots2--4324a";
export var dots3 = "Milestones-module--dots3--6007c";
export var dots4 = "Milestones-module--dots4--6100a";
export var dots5 = "Milestones-module--dots5--fc552";
export var dotsR = "Milestones-module--dotsR--a43db";
export var heading = "Milestones-module--heading--6a3b6";
export var leftText = "Milestones-module--leftText--da17b";
export var mobBg = "Milestones-module--mobBg--471e1";
export var number = "Milestones-module--number--07950";
export var number2 = "Milestones-module--number2--459b1";
export var peakNumber = "Milestones-module--peakNumber--6da18";
export var peakNumber2 = "Milestones-module--peakNumber2--d8f2c";
export var rec = "Milestones-module--rec--2c900";
export var rightText = "Milestones-module--rightText--37b58";
export var serial2 = "Milestones-module--serial2--ee4e9";
export var step = "Milestones-module--step--d9bd5";