import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Frame from "../../images/about-us/Frame.png"
import Vector from "../../images/about-us/Vector.png"
import arrow4 from "../../images/about-us/arrow4.png"
import cir from "../../images/about-us/cir.png"
import customer from "../../images/about-us/customer-satisfaction-rate.svg"
import developers from "../../images/about-us/developers-and-engineers.svg"
import doots from "../../images/about-us/doots.png"
import dots from "../../images/about-us/dots.png"
import dots1 from "../../images/about-us/dots1.png"
import global from "../../images/about-us/global-office.svg"
import happy from "../../images/about-us/happy-customers.svg"
import num from "../../images/about-us/num.svg"
import years from "../../images/about-us/years-of-experience.svg"
import ViewableCountUp from "../../utils/ViewableCountUp"
import * as styles from "./Engineering.module.scss"

const cardsData = [
  {
    img: num,
    title: 300,
    sign: `+`,
    description: `Successful Projects`,
  },
  {
    img: customer,
    title: 97,
    sign: `%`,
    description: `Customer Satisfaction Rate`,
  },
  {
    img: developers,
    title: 500,
    sign: `+`,
    description: `Developers & Engineers`,
  },
  {
    img: years,
    title: 10,
    sign: `+`,
    description: `Years of Experience`,
  },
  {
    img: global,
    title: 3,
    sign: ``,
    description: `Global Offices`,
  },
  {
    img: happy,
    title: 150,
    sign: `+`,
    description: `Happy Customers`,
  },
]

const Engineering = ({ strapiData }) => {
  return (
    <div className={styles.number}>
      <img
        src={arrow4}
        className={styles.arrow4}
        decoding="async"
        loading="lazy"
        alt="circle"
      />
      <img
        src={doots}
        className={styles.doots}
        decoding="async"
        loading="lazy"
        alt="circle"
      />
      <img
        src={dots1}
        className={styles.dots1}
        decoding="async"
        loading="lazy"
        alt="circle"
      />
      <Container>
        <img
          src={cir}
          className={styles.cir}
          decoding="async"
          loading="lazy"
          alt="circle"
        />
        <img
          src={Frame}
          className={styles.Frame}
          decoding="async"
          loading="lazy"
          alt="circle"
        />
        <h2 dangerouslySetInnerHTML={{ __html: strapiData?.title }} />
        <p dangerouslySetInnerHTML={{ __html: strapiData?.subTitle }} />
        <div className={styles.cardsData}>
          <Row className="gap-30">
            {cardsData?.map((item, index) => (
              <Col xl={4} md={6} key={index}>
                <div className={styles.cards}>
                  <div className={styles.imgBg}>
                    <img
                      src={item?.img}
                      decoding="async"
                      loading="lazy"
                      alt="Slider"
                    />
                  </div>
                  <div className={styles.years}>
                    <ViewableCountUp
                      start={0}
                      end={item?.title}
                      duration={5}
                      suffix={item?.sign}
                    />
                    <p>{item?.description}</p>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </div>
      </Container>
      <img
        src={Vector}
        className={styles.Vector}
        decoding="async"
        loading="lazy"
        alt="circle"
      />
      <img
        src={dots}
        className={styles.dots}
        decoding="async"
        loading="lazy"
        alt="circle"
      />
    </div>
  )
}

export default Engineering
