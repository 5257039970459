import { Link } from "gatsby"
import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import * as styles from "./Curious.module.scss"

const TextBtnStrip = ({ strapiData }) => {
  return (
    <div className={styles.ready}>
      <Container>
        <Row className="align-items-center">
          <Col xl={9}>
            <p
              className="mb-0"
              dangerouslySetInnerHTML={{ __html: strapiData?.title }}
            />
          </Col>
          <Col xl={3}>
            <Link to={strapiData?.buttons[0]?.url} className={styles.diveBtn}>
              {strapiData?.buttons[0]?.title}
            </Link>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default TextBtnStrip
